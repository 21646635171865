<template>
  <div class="d-flex align-content-center justify-content-center vh-100">
    <div class="fixed-width">
      <b-card class="py-2 px-2">
        <b-link class="d-flex align-content-center mb-2">
          <vuexy-logo />
          <h4 class="ml-1 text--logo">
            Case Management System
          </h4>
        </b-link>
        <b-card-title
          title-tag="h3"
          class="text--logo mb-1">
          Welcome
        </b-card-title>
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent>
            <b-form-group
              label="Username"
              label-for="login-email"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Username or Email"
                rules="required">
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Password"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required">
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password" />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              block
              @click="validationForm">
              Login
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  created () {
    if (this.profile) {
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions({
      signIn: 'User/signIn'
    }),
    async validationForm () {
      try {
        const validate = await this.$refs.loginValidation.validate()
        if (validate) {
          // const { data: { user, token } } = await AuthService.login({ username: this.userEmail, password: this.password })
          // if (user.role !== 'api') {
          //   throw new Error('Not Unauthorized')
          // }
          await this.signIn({ username: this.userEmail, password: this.password })
          // user.ability = [{ action: 'manage', subject: 'all' }]
          // console.log(user)
          // console.log(token)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Success',
              icon: 'EditIcon',
              variant: 'success'
            }
          })
          this.$router.go(0)
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.text--logo {
  font-family: sans-serif;
}
.fixed-width {
  margin: auto;
  max-width: 100%;
}
</style>
